import moment from 'moment'
import store from '../store'

export default {
  methods: {
    formatPhoneNumber(phoneNumber) {
      let cleaned = ('' + phoneNumber).replace(/\D/g, '')
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      }
      return null
    },
    formatPhoneNumberWhileTyping(number) {
      let phone = number
      phone = phone.replace(/[^\d]/g, '');
      phone = phone.substring(0, 10)
      var size = phone.length
      if (size == 0) {
        phone = phone + ''
      } else if (size < 4) {
        phone = '(' + phone
      } else if (size < 7) {
        phone = '(' + phone.substring(0, 3) + ') ' + phone.substring(3, 6)
      } else {
        phone = '(' + phone.substring(0, 3) + ') ' + phone.substring(3, 6) + '-' + phone.substring(6, 10)
      }
      if (this.customerInfo) this.customerInfo.phone = phone
      if (this.signUpInfo) this.signUpInfo.phone = phone
      if (this.addressInputs) this.addressInputs.phone = phone
      if (this.guestInfo) this.guestInfo.phone = phone
      if (this.phone) this.phone = phone
      return phone
    },
    formatPrice(price) {
      if (price == null) return ''
      let formattedPrice = price / 100
      let priceType = 'plus'
      if (formattedPrice.toString().charAt(0) === '-') {
        priceType = 'minus'
        formattedPrice = formattedPrice.toString().slice(1)
        formattedPrice = parseFloat(formattedPrice)
      }
      let currency = store.state.storeConfig ? store.state.storeConfig.localization.currency_symbol : '$'
      formattedPrice = formattedPrice.toFixed(2)
      if (priceType == 'minus') {
        return '-' + currency + formattedPrice
      }
      return currency + formattedPrice
    },
    formatPriceForTip(price) {
      if (price == null) return ''
      let formattedPrice = price / 100
      let priceType = 'plus'
      if (formattedPrice.toString().charAt(0) === '-') {
        priceType = 'minus'
        formattedPrice = formattedPrice.toString().slice(1)
        formattedPrice = parseFloat(formattedPrice)
      }
      let currency = store.state.storeConfig ? store.state.storeConfig.localization.currency_symbol : '$'
      formattedPrice = Math.floor(formattedPrice * 100) / 100;
      formattedPrice = formattedPrice.toFixed(2)
      if (priceType == 'minus') {
        return '-' + currency + formattedPrice
      }
      return currency + formattedPrice
    },
    formatDate(date, showHour) {
      if (showHour) return moment(date).format('MMM D, YYYY, h:mm A')
      return moment(date).format('MMM D, YYYY')
    },
    formatNameWhileTyping(name) {
      let formattedName = name.replace(/^\s+/, '').replace(/[^a-zA-Z\s]/g, ''); 
      if (this.signUpInfo) this.signUpInfo.firstName = formattedName;
      if (this.customerInfo) this.customerInfo.first_name = formattedName;
      if (this.first_name) this.first_name = formattedName;
      if (this.creditCardData) this.creditCardData.first_name = formattedName;
      if (this.guestInfo) this.guestInfo.firstName = formattedName;
      return formattedName;
    },
    formatLastNameWhileTyping(lastName) {
      let formattedLastName = lastName.replace(/^\s+/, '').replace(/[^a-zA-Z\s]/g, '');
      if (this.signUpInfo) this.signUpInfo.lastName = formattedLastName;
      if (this.customerInfo) this.customerInfo.last_name = formattedLastName;
      if (this.last_name) this.last_name = formattedLastName;
      if (this.creditCardData) this.creditCardData.last_name = formattedLastName;
      if (this.guestInfo) this.guestInfo.lastName = formattedLastName;
      return formattedLastName;
    },
    formatEmailWhileTyping(email) {
      let formattedEmail = email;
      formattedEmail = formattedEmail.replace(/[^a-z0-9@.+_-]/gi, '');
      if (this.customerInfo) this.customerInfo.email = formattedEmail;
      if (this.signUpInfo) this.signUpInfo.email = formattedEmail;
      if (this.guestInfo) this.guestInfo.email = formattedEmail;
      if (this.forgotPassword) this.forgotPassword.email = formattedEmail;
      if (this.loginInfo) this.loginInfo.email = formattedEmail;
      if (this.email) this.email = formattedEmail;
      return formattedEmail;
    },
  },
}
